<template>
  <div>
    <div v-if="!activateView">
      <div id="currentSelectedStudy">
        <b>TBD</b>
      </div>
    </div>
    <div v-if="activateView">
      <div id="currentSelectedStudy">
        <b>Current selected study:</b> ID: 4555 NCT00588770 E1305...
      </div>
      <br>

      <div style="display: flex; flex-direction: row;">
        <div class="gridLength">
          <kendo-datasource
            :ref="'availableCitationDataSource'"
            :page-size="4"
            :data="citationDataSource.data">
          </kendo-datasource>
          <div style="display: flex; flex-direction: column;">
            <div><b>Available Citations (Not mapped to this study)</b></div>
            <div style="display: flex; flex-direction: row;">
              <div><b><u><a href="#">Show Abstract</a></u></b></div>
            </div>
            <div style="display: flex; flex-direction: row;">
              <div><b>Show Full Text:&nbsp;</b></div>
                <kendo-datasource
                :ref="'documentSelector'"
                :data="documentDataSource.data">
                </kendo-datasource>

                <button>ShowAll</button>
            </div>
            <div><input type="checkbox"> Filter by citations with a mention of Study ID or Study Acronym in the abstract or in the file</div>
            <div style="display: flex; flex-direction: row;">
              <div>Show citations at stage(s):&nbsp;</div>
              <div>
                <kendo-datasource
                  :ref="'stageSelectorDataSource'"
                  :data="stageSelector">
                </kendo-datasource>
                <kendo-multiselect
                  :id="'stageSelector'"
                  :data-source-ref="'stageSelectorDataSource'"
                  :data-value-field="'SelectorIdentity'"
                  :data-text-field="'SelectorName'">
                </kendo-multiselect>
              </div>
            </div>
          </div>
          <kendo-grid
            :id="'availableCitationGrid'"
            :ref="'availableCitationGrid'"
            :data-source-ref="'availableCitationDataSource'"
            :selectable="selectable"
            :pageable="pageable"
            :filterable="filterable"
            :columns="columnsAvailable">
          </kendo-grid>
        </div>
        <!-- <div style="display: flex; flex-direction: column; padding-left: 5px; padding-right: 5px; padding-top: 100px;">
          <a href="#" class="k-button k-button-icon">
            <span class="k-icon k-i-arrow-60-right"></span>
          </a>
          <a href="#" class="k-button k-button-icon">
            <span class="k-icon k-i-arrow-60-left"></span>
          </a>
        </div> -->
        <div style="display: flex; flex-direction: column; padding-top: 150px;">
          <button>Map<span class="k-icon k-i-arrow-60-right"></span></button>
          <button><span class="k-icon k-i-arrow-60-left"></span>UnMap</button>
        </div>
        <br>
        <br>
        <div class="gridLength">
          <kendo-datasource
            :ref="'selectedCitationDataSource'"
            :page-size="4"
            :data="citationDataSource.data">
          </kendo-datasource>
          <div style="display: flex; flex-direction: row; justify-content: space-between; padding-bottom: 0px;">
            <div style="display: flex; flex-direction: column;">
              <div><b>Mapped Citations</b></div>
              <div style="display: flex; flex-direction: row;">
              <div><b><u><a href="#">Show Abstract</a></u></b></div>
            </div>
            <div style="display: flex; flex-direction: row;">
              <div><b>Show Full Text:&nbsp;</b></div>
                <kendo-dropdownlist
                  :ref="'documentSelectora'"
                  :data-source-ref="'documentSelector'"
                  :data-value-field="'DocumentIdentity'"
                  :data-text-field="'DocumentName'">
                </kendo-dropdownlist>
                <button>Show all</button>
            </div>
              <div>This table shows all citations mapped to this study.  It may differ from the citation list on the main screen because of the selected filters selected on that screen.&nbsp;</div>
              <div><input type="checkbox"> Filter by citations without a mention of Study ID or Study Acronym in the abstract or in the file</div>
              <div style="display: flex; flex-direction: row;">
              <!-- <div>Show citations at stage(s):&nbsp;</div> -->
              <!-- <div>
                <kendo-datasource
                  :ref="'stageSelectorDataSource'"
                  :data="stageSelector">
                </kendo-datasource>
                <kendo-multiselect
                  :id="'stageSelector'"
                  :data-source-ref="'stageSelectorDataSource'"
                  :data-value-field="'SelectorIdentity'"
                  :data-text-field="'SelectorName'">
                </kendo-multiselect>
              </div> -->
            </div>
            </div>
            <div style="display: flex; flex-direction: row; margin: 5px;">
              <div class="grid-header-main-citation" style="width: 30px; heignt: 10px;">&nbsp;</div>
              <div style="margin-left: 5px;">Main Citation</div>
            </div>
          </div>
          <kendo-grid
            :id="'selectedCitationGrid'"
            :ref="'selectedCitationGrid'"
            :data-source-ref="'selectedCitationDataSource'"
            :selectable="selectable"
            :pageable="pageable"
            :columns="columnsSelected"
            :change="selectRow">
          </kendo-grid>
        </div>
        <div style="display: flex; flex-direction: column; padding-left: 0px; padding-right: 0px; padding-top: 100px; border: 0px solid black;">
          <div style="display: flex; flex-direction: row; padding: 5px; width: 150px">
            <button style="width:120px" ref="buttonMain">Make it main citation</button>
          </div>
          <div style="display: flex; flex-direction: row; padding: 5px;">
            <button style="width:120px" ref="buttonSub">Make it subsequent citation</button>
          </div>
        </div>
      </div>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <div>
          <div>
            <button style="width: 120px; height: 20px;">Apply and Exit</button>
            <button style="width: 120px; height: 20px;">Apply and Next Study</button>
          </div>
        </div>
        <div>
          <div>
            <button style="width: 120px; height: 20px;">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CitationDataSource from '@/assets/data/Citation.json'
import StageDataSource from '@/assets/data/Stage.json'
import DocumentDataSource from '@/assets/data/Document.json'

export default {
  name: 'citation-select-b',
  data () {
    return {
      activateView: true,
      stageSelector: [
        { SelectorIdentity: 1, SelectorName: 'All' },
        { SelectorIdentity: 2, SelectorName: 'Abstract Screening' },
        { SelectorIdentity: 3, SelectorName: 'Full Text Screening' },
        { SelectorIdentity: 4, SelectorName: 'Study Mapping' },
        { SelectorIdentity: 5, SelectorName: 'Full Data Extraction' },
        { SelectorIdentity: 6, SelectorName: 'Excluded' }
      ],
      columnsAvailable: [
        {
          title: 'Main Citation Fields',
          width: '90px',
          headerAttributes: { class: 'grid-header-de1' },
          columns: [
            { field: 'PublicationIdentity', title: 'ID', width: '60px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Author', title: 'Author', width: '120px', template: '<div id="truncate">#:Author#</div>', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Year', title: 'Year', width: '80px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Title', title: 'Title', width: '200px', template: '<div id="truncate">#:Title#</div>', headerAttributes: { class: 'grid-header-de1' } }
          ]
        },
        {
          title: 'Other Citation Fields',
          headerAttributes: { class: 'grid-header-de1' },
          columns: [
            { field: 'CurrentStage', title: 'Current Stage', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'MovedFrom', title: 'Moved From', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'TypeOfPublication', title: 'Type Of Publication', width: '200px', template: '<div id="truncate">#:TypeOfPublication#</div>', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Volume', title: 'Volume', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Issue', title: 'Issue', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Pages', title: 'Page(s)', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Conference Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Date(s) of Conference', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Report Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Organizing Body', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Date of Report', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DatabaseName', title: 'Name of Database', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'AccessionNumber', title: 'Accession Number', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DOI', title: 'DOI', width: '200px', template: '<div id="truncate">#:DOI#</div>', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'AddedMaterials', title: 'Added Materials', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'PrimaryPublication', title: 'Primary Publication', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Has Flagged Annotations', width: '200px', headerAttributes: { class: 'grid-header-de1' } }
          ]
        }
      ],
      columnsSelected: [
        {
          title: 'Main Citation Fields',
          width: '90px',
          headerAttributes: { class: 'grid-header-de1' },
          columns: [
            { field: 'PublicationIdentity', title: 'ID', width: '60px', headerAttributes: { class: 'grid-header-de1' }, attributes: { class: '# if(PublicationIdentity.includes("1234")) { # grid-header-main-citation # } #' } },
            { field: 'Author', title: 'Author', width: '120px', template: '<div id="truncate">#:Author#</div>', headerAttributes: { class: 'grid-header-de1' }, attributes: { class: '# if(PublicationIdentity.includes("1234")) { # grid-header-main-citation # } #' } },
            { field: 'Year', title: 'Year', width: '80px', headerAttributes: { class: 'grid-header-de1' }, attributes: { class: '# if(PublicationIdentity.includes("1234")) { # grid-header-main-citation # } #' } },
            { field: 'Title', title: 'Title', width: '200px', template: '<div id="truncate">#:Title#</div>', headerAttributes: { class: 'grid-header-de1' }, attributes: { class: '# if(PublicationIdentity.includes("1234")) { # grid-header-main-citation # } #' } }
          ]
        },
        {
          title: 'Other Citation Fields',
          headerAttributes: { class: 'grid-header-de1' },
          columns: [
            { field: 'CurrentStage', title: 'Current Stage', width: '200px', headerAttributes: { class: 'grid-header-de1' }, attributes: { class: '# if(PublicationIdentity.includes("1234")) { # grid-header-main-citation # } #' } },
            { field: 'MovedFrom', title: 'Moved From', width: '200px', headerAttributes: { class: 'grid-header-de1' }, attributes: { class: '# if(PublicationIdentity.includes("1234")) { # grid-header-main-citation # } #' } },
            { field: 'TypeOfPublication', title: 'Type Of Publication', width: '200px', template: '<div id="truncate">#:TypeOfPublication#</div>', headerAttributes: { class: 'grid-header-de1' }, attributes: { class: '# if(PublicationIdentity.includes("1234")) { # grid-header-main-citation # } #' } },
            { field: 'Volume', title: 'Volume', width: '200px', headerAttributes: { class: 'grid-header-de1' }, attributes: { class: '# if(PublicationIdentity.includes("1234")) { # grid-header-main-citation # } #' } },
            { field: 'Issue', title: 'Issue', width: '200px', headerAttributes: { class: 'grid-header-de1' }, attributes: { class: '# if(PublicationIdentity.includes("1234")) { # grid-header-main-citation # } #' } },
            { field: 'Pages', title: 'Page(s)', width: '200px', headerAttributes: { class: 'grid-header-de1' }, attributes: { class: '# if(PublicationIdentity.includes("1234")) { # grid-header-main-citation # } #' } },
            { title: 'Conference Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Date(s) of Conference', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Report Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Organizing Body', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Date of Report', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DatabaseName', title: 'Name of Database', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'AccessionNumber', title: 'Accession Number', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DOI', title: 'DOI', width: '200px', template: '<div id="truncate">#:DOI#</div>', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'AddedMaterials', title: 'Added Materials', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'PrimaryPublication', title: 'Primary Publication', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Has Flagged Annotations', width: '200px', headerAttributes: { class: 'grid-header-de1' } }
          ]
        }
      ],
      pageable: {
        refresh: true,
        pageSizes: true,
        buttonCount: 2,
        pageSize: 5
      },
      selectable: 'multiple row',
      nonSelectable: false,
      groupable: false,
      nonFilterable: false,
      filterable: {
        operators: {
          number: {
            eq: 'Equal to',
            neq: 'Not equal to',
            gt: 'Greater than',
            gte: 'Greater than or equal to',
            lt: 'Less than',
            lte: 'Less than or equal to'
          }
        }
      },
      sortable: true,
      nonSortable: false,
      scrollable: true,
      citationDataSource: CitationDataSource,
      stageDataSource: StageDataSource,
      documentDataSource: DocumentDataSource
    }
  },
  methods: {
    selectRow: function () {
      var selectedRows = this.$refs.selectedCitationGrid.kendoWidget().select()
      for (var i = 0; i < selectedRows.length; i++) {
        var dataItem = this.$refs.selectedCitationGrid.kendoWidget().dataItem(selectedRows[i])
        if (dataItem.PublicationIdentity === '1234') {
          this.$refs.buttonMain.className = 'buttonMain-disabled'
          this.$refs.buttonSub.className = 'buttonSub'
        } else {
          this.$refs.buttonMain.className = 'buttonMain'
          this.$refs.buttonSub.className = 'buttonSub-disabled'
        }
      }
    }
  }
}
</script>

<style scoped>
  .gridLength {
    width: 500px;
    height: 200px;
  }

  .k-button {
    padding: 0;
    margin: 0;
    background-color: red;
  }
  .k-i-arrow-60-up {
    background-color:rgb(0, 105, 216);
    background-clip: border-box;
  }
  .k-icon:before {
    color: white !important;
  }
  .k-i-arrow-60-down {
    background-color:rgb(0, 105, 216);
  }
  .buttonMain {
    grid-row: 1 / 1;
    grid-column: 2 / 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: rgb(0, 105, 216);
    padding: 5px;
    width: 120px;
}

.buttonMain-disabled {
    grid-row: 1 / 1;
    grid-column: 2 / 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #B7B7B7;
    padding: 5px;
    width: 120px;
    color: #c0c0c0;
    background-color: rgb(183, 183, 183);
    padding: 5px;
    cursor: not-allowed !important;
    opacity:0.4;
}
.buttonSub {
    grid-row: 1 / 1;
    grid-column: 2 / 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: rgb(0, 105, 216);
    padding: 5px;
    width: 120px;
}

.buttonSub-disabled {
    grid-row: 1 / 1;
    grid-column: 2 / 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #B7B7B7;
    padding: 5px;
    width: 120px;
    color: #c0c0c0;
    background-color: rgb(183, 183, 183);
    padding: 5px;
    cursor: not-allowed !important;
    opacity:0.4;
}
</style>
