<template>
  <div style="width: 400px">
    <FormEntryContainer title="Study ID" type="text" size="80"></FormEntryContainer>
    <FormEntryContainer title="Study Acronym" type="text" size="80"></FormEntryContainer>
    <FormEntryContainer title="Study Description" type="textarea" size="900"></FormEntryContainer>
    <button style="width: 100px; height: 20px;">Save</button>
    <button style="width: 100px; height: 20px;">Cancel</button>
  </div>
</template>

<script>
import FormEntryContainer from '@/containers/FormEntryContainer'

export default {
  name: 'add-new-study',
  components: {
    FormEntryContainer
  }
}
</script>
