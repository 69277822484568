<template>
  <div>
    <div v-if="!addNewStudyView">
      <div id="currentSelectedStudy" style="display: flex; flex-direction: column;">
        <div><b>Current selected citation:</b>  Collella, 1994, Randomized phase...</div>
        <div><b><u><a href="#">Show Abstract</a></u></b></div>
        <div style="display: flex; flex-direction: row;">
              <div><b>Show Full Text:&nbsp;</b></div>
                <kendo-datasource
                :ref="'documentSelector'"
                :data="documentDataSource.data">
                </kendo-datasource>
                <kendo-dropdownlist
                  :ref="'documentSelector'"
                  :data-source-ref="'documentSelector'"
                  :data-value-field="'DocumentIdentity'"
                  :data-text-field="'DocumentName'">
                </kendo-dropdownlist>
                <button>ShowAll</button>
            </div>
      </div>
      <br>
      <div style="display: flex; flex-direction: row; justify-content: flex-start;">
        <button @click="addNewStudy()">Add Study</button><button @click="editStudy()">Edit Study</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button @click="editStudy()">Delete Study</button>
      </div>
      <div style="display: flex; flex-direction: row;">
        <div>
          <kendo-datasource
            :ref="'availableStudyDataSource'"
            :data="studyDataSource.data">
          </kendo-datasource>
          <div style="display: flex; flex-direction: column;">
            <div><b>Available Studies</b></div>
            <div><input type="checkbox">Filter by studies mentioned in this citation</div>
          </div>
          <kendo-grid
            :id="'availableStudyGrid'"
            :ref="'availableStudyGrid'"
            :data-source-ref="'availableStudyDataSource'"
            :selectable="selectable">
            <kendo-grid-column field="StudyID" title="Study ID" ></kendo-grid-column>
            <kendo-grid-column field="StudyAcronym" title="Acronym"></kendo-grid-column>
            <kendo-grid-column field="StudyDescription" title="Description"></kendo-grid-column>
          </kendo-grid>
        </div>
        <div style="display: flex; flex-direction: column; padding-top: 150px;">
          <button style="display: flex; flex-direction: row">Map<span class="k-icon k-i-arrow-60-right"></span></button>
          <button style="display: flex; flex-direction: row"><span class="k-icon k-i-arrow-60-left"></span>UnMap</button>
        </div>
        <br>
        <br>
        <br>
        <br>
        <div style="padding-bottom: 20px;">
          <kendo-datasource
            :ref="'selectedStudyDataSource'"
            :data="studyDataSource.data"
            :filter="filter">
          </kendo-datasource>
          <div style="display: flex; flex-direction: column;">
            <div><b>Mapped Studies</b></div>
            <div><input type="checkbox">Filter by studies not mentioned in this citation</div>
          </div>
          <kendo-grid
            :id="'selectedStudyGrid'"
            :ref="'selectedStudyGrid'"
            :data-source-ref="'selectedStudyDataSource'"
            :selectable="selectable"
            :columns="columnsSelected">
          </kendo-grid>
        </div>
      </div>
      <br>
      <br>
      <div style="display: flex; flex-direction: row;">
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <div>
          <div>
            <button style="width: 120px; height: 20px;">Apply and Exit</button>
            <button style="width: 120px; height: 20px;">Apply and Next Citation</button>
          </div>
        </div>
        <div>
          <div>
            <button style="width: 120px; height: 20px;">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="addNewStudyView">
      <div style="width: 400px">
        <FormEntryContainer title="Study ID" type="text" size="80"></FormEntryContainer>
        <FormEntryContainer title="Study Acronym" type="text" size="80"></FormEntryContainer>
        <FormEntryContainer title="Study Description" type="textarea" size="900"></FormEntryContainer>
        <div class="form-entry-container">
          <div class="form-entry-label">Study Tags</div>
          <div class="form-entry-input">
            <Tags></Tags>
          </div>
        </div>
        <button style="width: 100px; height: 20px;">Save</button>
        <button style="width: 100px; height: 20px;" @click="cancelNewStudyView()">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>

import DocumentDataSource from '@/assets/data/Document.json'
import CitationDataSource from '@/assets/data/Citation.json'
import StageDataSource from '@/assets/data/Stage.json'
import StudyDataSource from '@/assets/data/Study.json'
import Tags from '@/views/secure/manage/templates/Tags'
import FormEntryContainer from '@/containers/FormEntryContainer'
import $ from 'jquery'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'citation-select',
  components: {
    FormEntryContainer,
    Tags
  },
  data () {
    return {
      isRemoved: false,
      addNewStudyView: false,
      citationSelector: [
        { SelectorIdentity: 1, SelectorName: 'None' },
        { SelectorIdentity: 2, SelectorName: 'ID: 1234 Ray-2019-Title' }
      ],
      stageSelector: [
        { SelectorIdentity: 1, SelectorName: 'All' },
        { SelectorIdentity: 2, SelectorName: 'Abstract Screening' },
        { SelectorIdentity: 3, SelectorName: 'Full Text Screening' },
        { SelectorIdentity: 4, SelectorName: 'Study Mapping' },
        { SelectorIdentity: 5, SelectorName: 'Full Data Extraction' },
        { SelectorIdentity: 6, SelectorName: 'Excluded' }
      ],
      stageSelectorB: [
        { SelectorIdentity: 5, SelectorName: 'Full Data Extraction' }
      ],
      columns: [
        {
          title: 'Main Citation Fields',
          headerAttributes: { class: 'grid-header-dark' },
          columns: [
            { field: 'PublicationIdentity', title: 'Citation ID', width: '100px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Author', title: 'Author', width: '120px', template: '<div id="truncate">#:Author#</div>', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Year', title: 'Year', width: '80px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Title', title: 'Title', width: '200px', template: '<div id="truncate">#:Title#</div>', headerAttributes: { class: 'grid-header-de1' } }
          ]
        },
        {
          title: 'Other Citation Fields',
          headerAttributes: { class: 'grid-header-de1' },
          columns: [
            { field: 'Abstract', title: 'Abstract', width: '90px', template: '<div id="truncate" style="color: blue;"><u>#:Abstract#</u></div>', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'FullText', title: 'Full Text Link', width: '200px', template: '<div id="truncate" style="color: blue;"><u>#:FullText#</u></div>', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'CurrentStage', title: 'Current Stage', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'MovedFrom', title: 'Moved From', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'TypeOfPublication', title: 'Type Of Publication', width: '200px', template: '<div id="truncate">#:TypeOfPublication#</div>', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Volume', title: 'Volume', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Issue', title: 'Issue', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Pages', title: 'Page(s)', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Conference Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Date(s) of Conference', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Report Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Organizing Body', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Date of Report', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DatabaseName', title: 'Name of Database', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'AccessionNumber', title: 'Accession Number', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DOI', title: 'DOI', width: '200px', template: '<div id="truncate">#:DOI#</div>', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'AddedMaterials', title: 'Added Materials', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'PrimaryPublication', title: 'Primary Publication', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { title: 'Has Flagged Annotations', width: '200px', headerAttributes: { class: 'grid-header-de1' } }
          ]
        }
      ],
      columnsSelected: [
        { field: 'StudyID', title: 'Selected Study ID', filterable: false, headerAttributes: { class: 'grid-header-dark' } },
        { field: 'StudyAcronym', title: 'Acronym', filterable: false, headerAttributes: { class: 'grid-header-dark' } },
        { field: 'StudyDescription', title: 'Description', filterable: false, headerAttributes: { class: 'grid-header-dark' } },
        { field: 'PrimaryPublication', title: 'Is Main Citation', template: '<input type="checkbox">', filterable: false, headerAttributes: { class: 'grid-header-dark' } }
      ],
      pageable: {
        refresh: true,
        pageSizes: true,
        buttonCount: 2,
        pageSize: 5
      },
      selectable: 'multiple row',
      nonSelectable: false,
      groupable: false,
      nonFilterable: false,
      filterable: {
        operators: {
          number: {
            eq: 'Equal to',
            neq: 'Not equal to',
            gt: 'Greater than',
            gte: 'Greater than or equal to',
            lt: 'Less than',
            lte: 'Less than or equal to'
          }
        }
      },
      sortable: true,
      nonSortable: false,
      scrollable: true,
      citationDataSource: CitationDataSource,
      studyDataSource: StudyDataSource,
      stageDataSource: StageDataSource,
      documentDataSource: DocumentDataSource
    }
  },
  methods: {
    removeCitation: function () {
      this.isRemoved = !this.isRemoved
      if (this.isRemoved) {
        $('#studySelectSection').removeClass().addClass('disableSection')
      } else {
        $('#studySelectSection').removeClass().addClass('enableSection')
      }
    },
    addNewStudy: function () {
      emitter.emit('changeStudyWindowTitle', 'Add new study')
      console.log(this)
      this.addNewStudyView = true
    },
    editStudy: function () {
      emitter.emit('changeStudyWindowTitle', 'Edit study')
      console.log(this)
      this.addNewStudyView = true
    },
    cancelNewStudyView: function () {
      emitter.emit('changeStudyWindowTitle', 'Edit mapped studies')
      this.addNewStudyView = false
    }
  }
}
</script>

<style>
  .disableSection {
    opacity: 0.5;
  }

  .enableSection {
    opacity: 1;
  }

  .k-icon:before {
    color: white !important;
  }
</style>
