<template>
  <div>
    <kendo-hierarchicaldatasource
      :ref="'tagDataSourceRef'"
      :data="tagsData.data"
      :schema="tagsData.schema">
    </kendo-hierarchicaldatasource>
    <kendo-dropdowntree
      :data-source-ref="'tagDataSourceRef'"
      :data-text-field="'tag'"
      :data-value-field="'tag'"
      :checkboxes-check-children="true"
      :check-all="checkAll"
      :tag-mode="'multiple'">
    </kendo-dropdowntree>
  </div>
</template>

<script>
import TagsData from '@/assets/data/Tags.json'

export default {
  name: 'sample-tags',
  data () {
    return {
      expanded: null,
      expandField: true,
      checkboxes: true,
      checkAll: false,
      autoBind: false,
      loadOnDemand: false,
      tagsData: TagsData
    }
  }
}
</script>

<style>

.k-popup-dropdowntree .k-icon:before {
    color: black !important;
}

.k-popup-dropdowntree .k-treeview {
    background-color: white;
}
</style>
